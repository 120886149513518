<template>
  <div>
    <h2>{{tip}}</h2>
  </div>
</template>

<script>
import { baseUrl } from '@/constants/baseUrl'
import { setUserItem, setToken, getUserItem } from '@/libs/storage'
import { getUserInfo } from '@/api'
let Base64 = require('js-base64').Base64

export default {
  name: 'Login',
  data () {
    return {
      baseUrl,
      tip: ''
    }
  },
  methods: {
    getCode () {
      var url = window.location.href
      var parameter = url.substring(url.indexOf('?') + 1)
      parameter = parameter.split('&')
      var reg = /code=/g
      var menuCode = ''
      for (var i = 0; i < parameter.length; i++) {
        reg.lastIndex = 0
        if (reg.test(parameter[i])) {
          menuCode = parameter[i].replace('code=', '')
          break
        }
      }
      return menuCode
    },
    init () {
      const redirect_uri = this.$route.query.redirect_uri
      const openid = getUserItem() ? getUserItem().openid : ''
      const url = Base64.decode(redirect_uri)
      let code = this.getCode()
      if (code && !openid) {
        let that = this
        that.tip = '自动登录中...'
        getUserInfo({
          code,
          state: 'getOpenid'
        }).then((response) => {
          setUserItem(response.data)
          setToken(response.data.ACCESS_TOKEN)
          location.href = url
        }).catch(function (error) {
          that.tip = error.message
        });
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
h2 {
  margin-top: 50%;
}
</style>
