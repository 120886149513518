import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home'
import Login from '@/views/login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '康盛华'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '康盛华'
    }
  },
  {
    path: '/lpky110p',
    name: 'lpky110p',
    component: () => import('@/views/lpky110p'),
    meta: {
      title: '康盛华'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
