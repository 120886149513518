/**
*  建议判断是否json类型
* @param {*} data
*/
export const isJsonType = (data) => {
    if (typeof data === 'string') {
        try {
            JSON.parse(data)
            return true
        } catch (e) {
            return false
        }
    }
}