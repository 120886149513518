import { isJsonType } from './utils'
import { STORAGE_KEY } from '@/libs/config'
const JSONbigString = require('json-bigint')({ storeAsString: true })

/**
 * @param {String} token token值
 * @description 存储token
 */
export const setToken = token => {
  return localStorage.setItem(STORAGE_KEY.TOKEN, token)
}

/**
 * @returns {String} token值
 * @description 读取token
 */
export const getToken = () => {
  const data = localStorage.getItem(STORAGE_KEY.TOKEN)
  return data
}

/**
 * @description 删除token
 */
export const removeToken = () => {
  return localStorage.removeItem(STORAGE_KEY.TOKEN)
}

/**
 * @param {String} key key值
 * @param {String} value value值
 * @description 本地存储
 */
export const setItem = (key, value) => {
  const _value = typeof value === 'string' ? value : JSON.stringify(value)
  return localStorage.setItem(key, _value)
}

/**
 * @param {String} key key值
 * @returns {String} 本地键值为key的value值
 * @description 读取本地存储
 */
export const getItem = key => {
  const data = localStorage.getItem(key)
  return isJsonType(data) ? JSONbigString.parse(data) : data
}

/**
 * @param {String} key key值
 * @description 删除指定key的数据
 */
export const removeItem = key => {
  return localStorage.removeItem(key)
}

/**
 * @description 清空本地存储数据
 */
export const removeAllItem = () => {
  localStorage.clear()
}

/**
 * @param {String} value value值
 * @description 设置用户信息
 */
export const setUserItem = value => {
  return setItem(STORAGE_KEY.USERINFO, value)
}

/**
 * @description 读取用户信息
 */
export const getUserItem = () => {
  return getItem(STORAGE_KEY.USERINFO)
}

/**
 * @description 删除用户信息
 */
export const removeUserItem = () => {
  return removeItem(STORAGE_KEY.USERINFO)
}

/**
 * @param {String} value appid
 * @description 存储appid
 */
export const setAppid = value => {
  return setItem(STORAGE_KEY.APPID, value)
}

/**
 * @description 获取appid
 */
export const getAppid = () => {
  return getItem(STORAGE_KEY.APPID)
}

/**
 * @description 删除appid
 */
export const removeAppid = () => {
  return removeItem(STORAGE_KEY.APPID)
}
