/**
* 本次存储数据键名
*/
export const STORAGE_KEY = {
  TOKEN: 'TOKEN', // token
  USERINFO: '_USER_INFO', // 用户信息
  APPID: '_APPID'
}

export default {
  cookieExpires: 30 // cookie过期时间
}
