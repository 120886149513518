import axios from '@/libs/axios'

/**
 * @description: 通用请求接口
 * @param {*} data 参数
 * @return {*}
 */

export function Bseobj (data) {
  return axios.request({
    url: '/wx/api/post',
    method: 'post',
    data
  })
}

export function getUserInfo (data) {
  return axios.request({
    url: '/wx/member/getUserInfoByCode',
    method: 'post',
    data
  })
}

/**
 * 批量获取文件
 */
export function getLinkArr (data) {
  return axios.request({
    baseURL: 'https://api.realqrs.com',
    url: `/upload/file/getLinkArr`,
    method: 'POST',
    data
  })
}

export function YZ110P_2B (data) {
  return Bseobj({ ...data, code: 'YZ110P_2B' })
}

export function YZ110P_2C (data) {
  return Bseobj({ ...data, code: 'YZ110P_2C' })
}

