import Axios from 'axios'
import { baseUrl } from '../constants/baseUrl'
import { Toast } from 'vant'
import {
  getUserItem,
  getToken,
  removeAllItem
} from '@/libs/storage'
import {
  isJsonType
} from '@/libs/utils'
import _ from 'lodash'

let Base64 = require('js-base64').Base64

// 超大整型的数字信息显示错误问题处理
const JSONbigString = require('json-bigint')({ storeAsString: true })

const redirect = () => {
  const appid = 'wxb4d39ed208066f84'
  const redirect_uri = "https://ksh.wx.boyastem.com/login?redirect_uri=" + Base64.encode(location.href);

  const authorize = {
    baseUrl: 'https://open.weixin.qq.com/connect/oauth2/authorize',
    appid: appid,
    redirect_uri: redirect_uri,
    response_type: 'code',
    scope: 'snsapi_userinfo',
    state: 'getOpenid'
  }

  window.location.href = `${authorize.baseUrl}?appid=${authorize.appid}&redirect_uri=${authorize.redirect_uri}&response_type=${authorize.response_type}&scope=${authorize.scope}&state=${authorize.state}#wechat_redirect`
}
class HttpRequest {
  constructor() {
    this.options = {
      method: '',
      url: ''
    }
    // 重复参数请求间隔
    this.sameRequestInterval = 1000
    // 存储请求队列
    this.queue = {}
  }
  // 销毁请求实例

  destroy (url) {
    delete this.queue[url]
    const queue = Object.keys(this.queue)
    return queue.length
  }

  // 请求拦截
  interceptors (instance, url) {
    // 添加请求拦截器
    instance.interceptors.request.use(
      config => {
        const openid = getUserItem() ? getUserItem().openid : ''
        config.data = Object.assign(config.data || {}, {
          openid
        })
        config.headers.Authorization = getToken() !== 'undefined' ? `Bearer ${getToken()}` : ''
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )
    // 添加响应拦截器
    instance.interceptors.response.use(
      res => {
        const { data } = res
        this.destroy(url)
        // 文件流处理
        // if (
        //   res.headers['content-type'] &&
        //   res.headers['content-type'].includes('application/octet-stream')
        // ) {
        //   return res
        // }
        if (data.code !== 0) {
          const errCode = [-3, 70001, 70002, 70003, 70004, 70005, 70006, 70007, 70008]
          if (errCode.includes(data.code)) {
            removeAllItem()
            redirect()
          }
          Toast(data.message)
          return Promise.reject(data)
        }
        return Promise.resolve(data)
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  // 创建实例
  create () {
    const conf = {
      baseURL: baseUrl,
      timeout: 150000,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      async: true,
      crossDomain: true,
      // withCredentials: true,
      // 处理长整型id
      transformResponse: [
        data => {
          if (isJsonType(data)) {
            return JSONbigString.parse(data)
          }
          return data
        }
      ]
    }
    return Axios.create(conf)
  }

  // 请求实例
  request (options) {
    if (options.method.toLowerCase() !== 'get') {
      const request = this.queue[options.url]
      const now = Date.now()
      if (
        request &&
        _.isEqual(options, request.options) &&
        now - request.timestamp < this.sameRequestInterval
      ) {
        return Promise.reject(new Error('请勿重复操作'))
      }
    }
    const instance = this.create()
    this.interceptors(instance, options.url)
    options = Object.assign({}, options)
    this.queue[options.url] = {
      instance,
      options,
      timestamp: Date.now()
    }
    return instance(options)
  }
}

const axios = new HttpRequest()

export default axios
