<template>
  <div style="background: #F7F8F8">
    <van-overlay :show="loading"
                 z-index="99">
      <van-loading style="margin-top: 280px"
                   size="30px"
                   vertical>加载中...</van-loading>
    </van-overlay>
    <div class="user-info">
      <div class="imgBox">
        <van-image round
                   width="52px"
                   height="52px"
                   :src="userInfo.headimgurl" />
      </div>
      <div class="name">{{userInfo.nickname || '-'}}</div>
    </div>
    <div class="list"
         v-for="(v, i) in list"
         :key="i">
      <div class="top">
        <van-image v-if="v.CCODE=='LPKY110P_1'"
                   width="72px"
                   height="72px"
                   :src="mianyi" />
        <van-image v-if="v.CCODE=='LPKY110P_2'"
                   width="72px"
                   height="72px"
                   :src="kangyang" />
        <van-image v-if="v.CCODE=='LPKY110P_3'"
                   width="72px"
                   height="72px"
                   :src="kangshuai" />
        <div class="title">{{v.ITEM_NAME}}</div>
      </div>
      <div class="content">
        <div><span class="bold">订单类型：</span><span>{{v.ITEM_TYPESTR}}</span></div>
        <div><span class="bold">订单编号：</span><span>{{v.ITEM_NO}}</span></div>
        <div><span class="bold">下单时间：</span><span>{{v.SVCORDERTIME}}</span></div>
      </div>
      <div class="btm">
        <div class="btn"
             @click="jump(v)">服务进度</div>
      </div>
    </div>
    <div class="nodata"
         v-if="!list.length">
      <van-image width="72px"
                 :src="nodata" />
    </div>
  </div>
</template>

<script>
import { YZ110P_2C } from '@/api'
import { getUserItem } from '@/libs/storage'
import mianyi from '@/images/mianyi2.png'
import kangshuai from '@/images/kangshuai2.png'
import kangyang from '@/images/kangyang2.png'
import nodata from '@/images/nodata.png'

export default {
  name: 'Home',
  data () {
    return {
      userInfo: getUserItem(),
      loading: false,
      list: [],
      mianyi,
      kangshuai,
      kangyang,
      nodata
    }
  },
  methods: {
    getData () {
      this.loading = true
      YZ110P_2C({
        WX_OPENID: this.userInfo && this.userInfo.openid ? this.userInfo.openid : ''
      }).then(({ data = [] }) => {
        this.list = data
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$toast(err.message)
      })
    },
    jump ({ ID, CCODE }) {
      this.$router.push({ path: 'lpky110p', query: { ID, CCODE } })
    }
  },
  created () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
.user-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    #f7f8f8 1%,
    #e3eaf1 16%,
    #b2c7df 47%,
    #638fc3 90%,
    #5283bd 100%
  );
  padding: 50px 0 30px;
  .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    width: 54px;
    height: 54px;
    border-radius: 100%;
  }
  .name {
    font-size: 12px;
    margin-top: 12px;
  }
}
.list {
  margin: 16px 8px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 1.04px 1.04px 4.16px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 12px;

  .top {
    display: flex;
    padding: 12px 12px 0 12px;
    /deep/ .van-image__img {
      border-radius: 8px;
    }
    .title {
      padding: 6px;
      font-weight: 600;
      color: #3e3a39;
    }
  }

  .content {
    margin: 10px;
    padding: 8px;
    line-height: 22px;
    background: #f7f8f8;
    border-radius: 12px;
    > div {
      border-bottom: 1px solid #eaebeb;
      padding: 5px;
      span {
        color: #3e3a39;
      }
      .bold {
        font-weight: 600;
        color: #3e3a39;
      }
    }
    > div:last-child {
      border: none;
    }
  }
  .btm {
    display: flex;
    justify-content: flex-end;
    .btn {
      border: 1px solid #0ba0fc;
      color: #0ba0fc;
      padding: 8px 16px;
      border-radius: 16px;
      text-align: center;
      margin: 10px;
      border-radius: 20px;
    }
  }
}
.nodata {
  margin: 16px;
  padding: 160px 0 200px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 1.04px 1.04px 4.16px 0px rgba(0, 0, 0, 0.1);
}
</style>
